import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import './App.css';
import './diagram.css';
import './IubendaScript.css';
import logoImage from './img/gorilla.png';
import IubendaScript from './IubendaScript';
import MachineLearningDiagram from './MachineLearningDiagram.js';


const AnimatedButton = ({ children, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const springProps = useSpring({
    scale: isHovered ? 1.1 : 1,
    color: isHovered ? '#ff6b6b' : '#4ecdc4',
  });
  return (
    <animated.button
      style={springProps}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      {children}
    </animated.button>
  );
};

const HtmlContent = ({ url, onClose, isArticle }) => {
  return (
    <div className={`iframe-container ${isArticle ? 'article-view' : ''}`}>
      <iframe 
        name="W3C"
        src={url}
        height="100%"
        width="1300"
        title="Content"
      >
        <p>Il browser in uso non supporta iframe</p>
      </iframe>
      <button className="close-button" onClick={onClose}>Chiudi</button>
    </div>
  );
};

const menuItems = [
  {
    name: 'Statistica descrittiva',
    subItems: [
      {name: 'Analisi del mercato immobiliare del Texas', href:'/statistica descrittiva/analisi-del-mercato-immobiliare-del-Texas.html', isInternal: true}
    ]
  },
  {
    name: 'Statistica Inferenziale',
    subItems: [
      {name: 'Modello statistico per prevedere il peso dei neonati', href:'/statistica inferenziale/statistica_inferenziale_peso_neonati.html', isInternal: true}
    ]  
  },
  {
    name: 'Machine Learning',
    subItems: [
      {name: 'Previsione di opportunità di Cross Sell', href:'machine learning/Previsione_di_opportunità_di_Cross_Sell.html', isInternal: true}
    ]
  },
  {
    name: 'Reti neurali',
    subItems: [{name: 'Sottocategoria 4A', href: '#', isInternal: true}]
  },
  {
    name: 'App e videogames',
    subItems: [
      {name: 'Panorama Mosaico', href: 'https://play.google.com/store/apps/details?id=com.spritedeveloper&hl=it', isExternal: true},
      {name: 'Galaxy Invasions (A breve sarà disponibile sul PlayStore)', href: 'https://gabriele-iocco.itch.io/galacticinvasions-from-71level', isExternal: true}
    ]
  },
  {
    name: 'Articoli pubblicati',
    subItems: [
      { name: 'Startrail e fluorescenza', href:'articoli/Startrail e bioluminescenza.html', isInternal: true, isArticle: true},
      { name: 'Panorama Mosaico', href:'articoli/Panorama mosaico.html', isInternal: true, isArticle: true}
    ]
  },
  {
    name: 'Diagrammi',
    subItems: [
      { name: 'Machine Learning', isDiagram: true }
    ]
  },
];



function App() {
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [openList, setOpenList] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);
  const [isArticleView, setIsArticleView] = useState(false);
  const [showDiagram, setShowDiagram] = useState(false);
  const [isDiagramLocked, setIsDiagramLocked] = useState(true);

  
  useEffect(() => {
    document.title = "Spritedeveloper"; // Imposta il titolo della pagina
  }, []);

  const toggleFile = () => {
    console.log('Toggling File menu');
    setIsFileOpen(!isFileOpen);
  };

  const toggleList = (listNumber) => {
    console.log('Toggling list:', listNumber);
    setOpenList(openList === listNumber ? null : listNumber);
  };

  const handlePageSelection = (subItem) => {
    console.log('Selecting page:', subItem.href);
    if (subItem.isExternal) {
      window.open(subItem.href, '_blank', 'noopener,noreferrer');
    } else if (subItem.isInternal) {
      setSelectedPage(subItem.href);
      setIsArticleView(subItem.isArticle);
    } else if (subItem.isDiagram) {
      setShowDiagram(true);
    }
    // Chiudi il menu File
    setIsFileOpen(false);
    // Resetta anche l'openList se necessario
    setOpenList(null);
  };

  const menuAnimation = useSpring({
    opacity: isFileOpen ? 1 : 0,
    transform: isFileOpen ? 'translateY(0px)' : 'translateY(-20px)',
  });
  

  return (
    <div className="App">
<noscript>
        <iframe 
          src="https://www.googletagmanager.com/ns.html?id=GTM-MVFWQK7J"
          height="0" 
          width="0" 
          style={{display: 'none', visibility: 'hidden'}}
          title="Google Tag Manager"
        ></iframe>
      </noscript>
      <div className="background-quadrants">
        <div className="quadrant top-left"></div>
        <div className="quadrant top-right"></div>
        <div className="quadrant bottom-left"></div>
        <div className="quadrant bottom-right"></div>
      </div>
      <header>
        <nav className="menu-container">
          <ul className="menu">
            <li>
              <AnimatedButton onClick={toggleFile}>File</AnimatedButton>
              {isFileOpen && (
                <animated.ul className="submenu" style={menuAnimation}>
                  {menuItems.map((item, index) => (
                    <li key={index}>
                      <AnimatedButton onClick={() => toggleList(index + 1)}>{item.name}</AnimatedButton>
                      {openList === index + 1 && item.subItems.length > 0 && (
                        <ul className="sublist">
                          {item.subItems.map((subItem, subIndex) => (
                            <li key={subIndex}>
                              <AnimatedButton 
                                onClick={() => handlePageSelection(subItem)}
                                aria-label={subItem.isExternal ? `Apri ${subItem.name} in una nuova scheda` : subItem.name}
                              >
                                {subItem.name}
                              </AnimatedButton>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </animated.ul>
              )}
            </li>
          </ul>
        </nav>
        <div className="title-container">
          <h1>Spritedeveloper</h1>
          <img src={logoImage} alt="Logo" className="logo" />
        </div>
      </header>
      {selectedPage && (
        <HtmlContent 
          url={selectedPage} 
          onClose={() => {
            setSelectedPage(null);
            setIsArticleView(false);
          }} 
          isArticle={isArticleView}
        />
      )}
      {showDiagram && (
  <div className="diagram-container">
    <h2 className="diagram-title">Mappa concettuale dell'AI</h2>
     <h3 className="diagram-subtitle">Trascina lo schermo, esegui lo zoom e clicca sui componenti</h3>
    <MachineLearningDiagram isLocked={isDiagramLocked} />
    <button className="toggle-lock-button" onClick={() => setIsDiagramLocked(!isDiagramLocked)}>
    {isDiagramLocked ? 'Sblocca' : 'Blocca'} diagramma
  </button>
  <button className="close-button" onClick={() => setShowDiagram(false)}>Chiudi</button>
</div>
)}
    {<footer>
    <IubendaScript />
      </footer>}
    </div>
  );
}

export default App;