import React, { useEffect, useState } from 'react';

const IubendaScript = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const userChoice = localStorage.getItem('cookieChoice');

    if (!userChoice) {
      setShowBanner(true);

      // Configurazione Iubenda
      window._iub = window._iub || [];
      window._iub.csConfiguration = {
        "siteId": 3721036,
        "cookiePolicyId": 15911545,
        "lang": "it",
        "banner": {
          "position": "bottom",
          "acceptButtonDisplay": true,
          "customizeButtonDisplay": true,
          "rejectButtonDisplay": true,
          "listPurposes": true
        },
        "perPurposeConsent": true,
        "consentOnContinuedBrowsing": false,
        "callback": {
          onPreferenceExpressed: function(preference) {
            localStorage.setItem('cookieChoice', preference.consent ? 'accepted' : 'rejected');
            setShowBanner(false);
          }
        }
      };

      // Funzione per caricare uno script
      const loadScript = (src) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        document.body.appendChild(script);
      };

      // Carica gli script di Iubenda
      loadScript("https://cs.iubenda.com/autoblocking/3721036.js");
      loadScript("//cdn.iubenda.com/cs/gpp/stub.js");
      loadScript("//cdn.iubenda.com/cs/iubenda_cs.js");

      // Carica lo script aggiuntivo fornito da Iubenda
      const loader = () => {
        const s = document.createElement("script");
        s.src = "https://cdn.iubenda.com/iubenda.js";
        const tag = document.getElementsByTagName("script")[0];
        tag.parentNode.insertBefore(s, tag);
      };

      if (window.addEventListener) {
        window.addEventListener("load", loader, false);
      } else if (window.attachEvent) {
        window.attachEvent("onload", loader);
      } else {
        window.onload = loader;
      }
    }
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      const userChoice = localStorage.getItem('cookieChoice');
      if (userChoice) {
        setShowBanner(false);
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  const openPreferences = (e) => {
    e.preventDefault();
    if (window._iub && window._iub.cs && window._iub.cs.api) {
      window._iub.cs.api.openPreferences();
    } else {
      console.warn('Iubenda API non è disponibile');
    }
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="iubenda-buttons-container">
      <a href="https://www.iubenda.com/privacy-policy/15911545" className="iubenda-button" title="Privacy Policy">Privacy Policy</a>
      <a href="https://www.iubenda.com/privacy-policy/15911545/cookie-policy" className="iubenda-button" title="Cookie Policy">Cookie Policy</a>
      <button
        onClick={openPreferences}
        className="iubenda-button"
      >
        Preferenze Cookie
      </button>
    </div>
  );
};

export default IubendaScript;