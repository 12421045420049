import React from 'react';
import { Handle } from 'reactflow';

const CustomTripleHandleNode = ({ data, style }) => {
  return (
    <div style={{...style, width: 'auto', padding: '10px 20px'}}>
      <Handle                       //In React Flow, sourceHandle nell'edge si riferisce all'ID della handle
        type="target"
        position={data.targetPosition}
        id="target"
        style={{ background: '#555' }}
      />
      <Handle                       //In React Flow, sourceHandle nell'edge si riferisce all'ID della handle
        type="target"
        position={data.targetPosition2}
        id="targetPosition2"
        style={{ background: '#555' }}
      />
      <Handle 
        type="source"                   //left
        position={data.sourcePosition1}
        id="source1"
        style={{ background: '#555' }}
      />
      <Handle 
        type="source"                    //bottom
        position={data.sourcePosition2}
        id="source2"
        style={{ background: '#555' }}
      />
      <Handle 
        type="source"
        position={data.sourcePosition3}
        id="source3"
        style={{ background: '#555' }}
      />
      <span>{data.label}</span>
    </div>
  );
};

export default CustomTripleHandleNode;