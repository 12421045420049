import React, { useState, useCallback }from 'react';
import ReactFlow, { Background, Controls } from 'reactflow';

import 'reactflow/dist/style.css';
import './diagram.css';

import CustomTripleHandleNode from './CustomHandle.jsx';
import CustomBezierEdge from './CustomBezierEdge';

const nodeTypes = {
  customTripleHandle: CustomTripleHandleNode,
};


export const nodeStyle = {
  backgroundColor: '#ffffff',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '3px',
  borderColor: '#b3cccc',
  edge:'#b3cccc',
  padding: '3px',
  fontSize: '12px',
  fontWeight: 'normal',
  color: '#333',
  textAlign: 'center',
  width: 150,
  height: 40,
  boxSizing: 'border-box',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const initialNodes = [
  {
    id: '1',
    position: { x: 100, y: -300 },
    data: { label: 'AI', isSelected: false,
            targetPosition: 'bottom',
            group: null},
    style: nodeStyle,
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
  },
  {
    id: '2',
    position: { x: 100, y: -200 },
    data: {
      label: 'Machine Learning',isSelected: false,
      sourcePosition1: 'bottom',
      sourcePosition2: 'right',
      sourcePosition3: 'left',
      group: null
          },
    type: 'customTripleHandle',
    style: nodeStyle,
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
  },

  {
    id: '2retiNeur',
    position: { x: 100, y: -100 },
    data: {
      label: 'Reti neurali',isSelected: false,
      targetPosition:  'left',
      targetPosition2: 'right',
      sourcePosition1: 'bottom',
      sourcePosition2: 'left',
      sourcePosition3: 'right',
      group: null
          },
    type: 'customTripleHandle',
    style: nodeStyle,
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
  },

  {
    id: '3',
    position: { x: 100, y: 0 },
    data: { label: 'Deep Learning', isSelected: false,
    targetPosition: 'left',
    targetPosition2: 'right',
    sourcePosition2: 'left',
    sourcePosition3: 'right',
    group: null},
    type: 'customTripleHandle',
    style: nodeStyle,
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },
  
  {
    id: '4',
    position: { x: -400, y: 200 },
    data: { 
      label: 'Apprendimento supervisionato', isSelected: false ,
      targetPosition:'top',
      sourcePosition1: 'left',
      sourcePosition2: 'bottom',
      group: null
          },
    type: 'customTripleHandle',
    style: nodeStyle,
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height, 
  },

  {
    id: '4pre',
    position: { x: -700, y: 250 },
    data: { 
      label: 'Preprocessing', isSelected: false ,
      targetPosition:'right',
      sourcePosition1:'left',
      group: null
          },
    type: 'customTripleHandle',
    style: nodeStyle,
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height, 
  },

  {
    id: '4e',
    position: { x: -700, y: -700 },
    data: { 
      label: "Riconoscimento immagini" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '4ee',
    position: { x: -700, y: -600 },
    data: { 
      label: "Elaborazione testi" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '4eee',
    position: { x: -700, y: -500 },
    data: { 
      label: "Sistemi di raccomandazione" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1: 'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '4e4',
    position: { x: -1000, y: -800 },
    data: { 
      label: "Suggerimenti su Amazon" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '4e5',
    position: { x: -1000, y: -700 },
    data: { 
      label: "Suggerimenti su Netflix" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '4e6',
    position: { x: -1000, y: -600 },
    data: { 
      label: "Suggerimenti su Spotify" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },
  
  {
    id: '4a',
    position: { x: 100, y: 200 },
    data: { label: 'Apprendimento non supervisionato', isSelected: false,
            targetPosition: 'left',
            targetPosition2: 'top'
     },
    type: 'customTripleHandle',
    style: nodeStyle,
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },
  
  {
    id: '4b',
    position: { x: 600, y: 200 },
    data: { label: 'Apprendimento per rinforzo', isSelected: false },
    style: nodeStyle,
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },
  
  {
    id: '5',
    position: { x: -1000, y: 300 },
    data: { label: 'Dati (dataset)', isSelected: false },
    style: nodeStyle, 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5a',
    position: { x: -1000, y: 400 },
    data: { 
      label: 'Dati etichettati', isSelected: false,
      targetPosition: 'top',
      sourcePosition1: 'left', 
      sourcePosition2: 'bottom',
      sourcePosition3: 'right'
    },
    style: nodeStyle, 
    type: 'customTripleHandle',
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5a2',
    position: { x: -1600, y: 300 },
    data: { 
      label: "Future Engineering" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1:'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5a3',
    position: { x: -1800, y: 200 },
    data: { 
      label: "Pulizia dati" , isSelected: false,
      targetPosition: 'right',
      sourcePosition2: 'right'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5esplorazione',
    position: { x: -2100, y: 0 },
    data: { 
      label: "Esplorazione dei dati" , isSelected: false,
      targetPosition: 'bottom',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5selez',
    position: { x: -1900, y: 0 },
    data: { 
      label: "Selezione feature" , isSelected: false,
      targetPosition: 'bottom',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5rimozDuplicati',
    position: { x: -1700, y: 0 },
    data: { 
      label: "Rimozione duplicati" , isSelected: false,
      targetPosition: 'bottom',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5valAnomali',
    position: { x: -1500, y: 0 },
    data: { 
      label: "Gestione valori anomali" , isSelected: false,
      targetPosition: 'bottom',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5gestErrori',
    position: { x: -2000, y: -100 },
    data: { 
      label: "Gestione errori" , isSelected: false,
      targetPosition: 'bottom',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5binning',
    position: { x: -1800, y: -100 },
    data: { 
      label: "Binning" , isSelected: false,
      targetPosition: 'bottom',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5elabTesto',
    position: { x: -1600, y: -100 },
    data: { 
      label: "Elaborazione Testo" , isSelected: false,
      targetPosition: 'bottom',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5serieTemp',
    position: { x: -1900, y: -200 },
    data: { 
      label: "Serie temporali" , isSelected: false,
      targetPosition: 'bottom',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5vettori',
    position: { x: -1700, y: -200 },
    data: { 
      label: "Caratteristiche vettoriali" , isSelected: false,
      targetPosition: 'bottom',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5a4',
    position: { x: -1400, y: 200 },
    data: { 
      label: "Trasformatori" , isSelected: false,
      targetPosition: 'left',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5normal',
    position: { x: -1000, y: 0 },
    data: { 
      label: "Normalizzazione Standardizzazione" , isSelected: false,
      targetPosition: 'left',
      sourcePosition1:'right'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5scaling',
    position: { x: -1000, y: -100 },
    data: { 
      label: "Scaling feature" , isSelected: false,
      targetPosition: 'left',
      sourcePosition1:'right'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5encoding',
    position: { x: -1000, y: -200 },
    data: { 
      label: "Encoding var categoriche" , isSelected: false,
      targetPosition: 'left',
      sourcePosition1:'right'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5imputazione',
    position: { x: -1000, y: -300 },
    data: { 
      label: "Imputazione" , isSelected: false,
      targetPosition: 'left',
      sourcePosition1:'right'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5ridDimens',
    position: { x: -1000, y: 100 },
    data: { 
      label: "Ridurre dimensionalità" , isSelected: false,
      targetPosition: 'left',
      sourcePosition1:'right'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5normalALG',
    position: { x: -800, y: 0 },
    data: { 
      label: "StandardScaler" , isSelected: false,
      targetPosition: 'left',
      
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5scalingALG',
    position: { x: -800, y: -100 },
    data: { 
      label: "MinMaxScaler" , isSelected: false,
      targetPosition: 'left',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5encodingALG',
    position: { x: -800, y: -200 },
    data: { 
      label: "OneHotEncoder" , isSelected: false,
      targetPosition: 'left',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5imputazioneALG',
    position: { x: -800, y: -300 },
    data: { 
      label: "Imputer" , isSelected: false,
      targetPosition: 'left',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5ridDimensALG',
    position: { x: -800, y: 100 },
    data: { 
      label: "PCA/LDA/Feature selection" , isSelected: false,
      targetPosition: 'left',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5b',
    position: { x: -2300, y: -300 },
    data: { 
      label: "Dati numerici" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1: 'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5c',
    position: { x: -2300, y: 200 },
    data: { 
      label: "Dati categoriali" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1: 'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },
 
  {
    id: '5d',
    position: { x: -2500, y: -400 },
    data: { 
      label: "Stimatori" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1:'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5e',
    position: { x: -2500, y: 100 },
    data: { 
      label: "Classificatori" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1:'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5f',
    position: { x: -2700, y: -500 },
    data: { 
      label: "Modelli di regressione" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1: 'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5RegressioneLineare',
    position: { x: -3000, y: -600 },
    data: { 
      label: "Regressione lineare" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1:'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },
                      {
                        id: 'OrdinaryLeastSquares',
                        position: { x: -3200, y: -500 },
                        data: { 
                          label: "Ordinary Least Squares" , isSelected: false,
                          targetPosition: 'right'
                        },
                        style: nodeStyle,
                        type: 'customTripleHandle', 
                        originalWidth: nodeStyle.width,
                        originalHeight: nodeStyle.height,
                        group: null
                      },

                      {
                        id: 'L2',
                        position: { x: -3200, y: -400 },
                        data: { 
                          label: "Ridge Regression (L2)" , isSelected: false,
                          targetPosition: 'right'
                        },
                        style: nodeStyle,
                        type: 'customTripleHandle', 
                        originalWidth: nodeStyle.width,
                        originalHeight: nodeStyle.height,
                        group: null
                      },

                      {
                        id: 'L1',
                        position: { x: -3200, y: -300 },
                        data: { 
                          label: "Lasso Regression (L1)" , isSelected: false,
                          targetPosition: 'right'
                        },
                        style: nodeStyle,
                        type: 'customTripleHandle', 
                        originalWidth: nodeStyle.width,
                        originalHeight: nodeStyle.height,
                        group: null
                      },

                      {
                        id: 'ElasticNet',
                        position: { x: -3200, y: -200 },
                        data: { 
                          label: "Elastic Net" , isSelected: false,
                          targetPosition: 'right'
                        },
                        style: nodeStyle,
                        type: 'customTripleHandle', 
                        originalWidth: nodeStyle.width,
                        originalHeight: nodeStyle.height,
                        group: null
                      },

  {
    id: '5RegressioneLogistica',
    position: { x: -3000, y: -700 },
    data: { 
      label: "Regressione logistica" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1:'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },
                  {
                    id: 'Binaria',
                    position: { x: -3500, y: -550 },
                    data: { 
                      label: "Binaria" , isSelected: false,
                      targetPosition: 'right',
                    },
                    style: nodeStyle,
                    type: 'customTripleHandle', 
                    originalWidth: nodeStyle.width,
                    originalHeight: nodeStyle.height,
                    group: null
                  },
                  {
                    id: 'Multinomiale',
                    position: { x: -3500, y: -450 },
                    data: { 
                      label: "Multinomiale" , isSelected: false,
                      targetPosition: 'right',
                    },
                    style: nodeStyle,
                    type: 'customTripleHandle', 
                    originalWidth: nodeStyle.width,
                    originalHeight: nodeStyle.height,
                    group: null
                  },
                  {
                    id: 'Ordinale',
                    position: { x: -3500, y: -350 },
                    data: { 
                      label: "Ordinale" , isSelected: false,
                      targetPosition: 'right',
                    },
                    style: nodeStyle,
                    type: 'customTripleHandle', 
                    originalWidth: nodeStyle.width,
                    originalHeight: nodeStyle.height,
                    group: null
                  },
  {
    id: '5KNN',
    position: { x: -3000, y: -800 },
    data: { 
      label: "KNN Regressor" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5RegressionePolinomiale',
    position: { x: -3000, y: -900 },
    data: { 
      label: "Regressione polinomiale" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5SVR',
    position: { x: -3000, y: -1000 },
    data: { 
      label: "SVR" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5decTree',
    position: { x: -3000, y: -1100 },
    data: { 
      label: "Decision Tree Regressor" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5rfr',
    position: { x: -3000, y: -1200 },
    data: { 
      label: "Random Forest Regressor" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5gbr',
    position: { x: -3000, y: -1300 },
    data: { 
      label: "Gradient Boosting Regressor" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5nnr',
    position: { x: -3000, y: -1400 },
    data: { 
      label: "Neural Network Regressor" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5gpr',
    position: { x: -3000, y: -1500 },
    data: { 
      label: "Gaussian Process Regression" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5alberiDec',
    position: { x: -3000, y: 900 },
    data: { 
      label: "Alberi decisionali" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1:'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

                    {
                      id: 'CART',
                      position: { x: -3200, y: 1050 },
                      data: { 
                        label: "Classif. and Regres. Trees" , isSelected: false,
                        targetPosition: 'right'
                      },
                      style: nodeStyle,
                      type: 'customTripleHandle', 
                      originalWidth: nodeStyle.width,
                      originalHeight: nodeStyle.height,
                      group: null
                    },

                    {
                      id: 'ID3',
                      position: { x: -3200, y: 1150 },
                      data: { 
                        label: "Iterative Dichotomiser 3" , isSelected: false,
                        targetPosition: 'right'
                      },
                      style: nodeStyle,
                      type: 'customTripleHandle', 
                      originalWidth: nodeStyle.width,
                      originalHeight: nodeStyle.height,
                      group: null
                    },

                    {
                      id: 'C4.5',
                      position: { x: -3200, y: 1250 },
                      data: { 
                        label: "C4.5" , isSelected: false,
                        targetPosition: 'right'
                      },
                      style: nodeStyle,
                      type: 'customTripleHandle', 
                      originalWidth: nodeStyle.width,
                      originalHeight: nodeStyle.height,
                      group: null
                    },

                    {
                      id: 'CHAID',
                      position: { x: -3200, y: 1350 },
                      data: { 
                        label: "Chi-squa. Autom. Intera. Detec." , isSelected: false,
                        targetPosition: 'right'
                      },
                      style: nodeStyle,
                      type: 'customTripleHandle', 
                      originalWidth: nodeStyle.width,
                      originalHeight: nodeStyle.height,
                      group: null
                    },

  {
    id: '5EnsembleMethods',
    position: { x: -3000, y: 800 },
    data: { 
      label: "Ensemble Methods" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1:'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

                      {
                        id: 'RandomForest',
                        position: { x: -3500, y: 900 },
                        data: { 
                          label: "Random Forest" , isSelected: false,
                          targetPosition: 'right',
                        },
                        style: nodeStyle,
                        type: 'customTripleHandle', 
                        originalWidth: nodeStyle.width,
                        originalHeight: nodeStyle.height,
                        group: null
                      },
                      {
                        id: 'LightGBM',
                        position: { x: -3500, y: 1000 },
                        data: { 
                          label: "LightGBM" , isSelected: false,
                          targetPosition: 'right',
                        },
                        style: nodeStyle,
                        type: 'customTripleHandle', 
                        originalWidth: nodeStyle.width,
                        originalHeight: nodeStyle.height,
                        group: null
                      },
                      {
                        id: 'CatBoost',
                        position: { x: -3500, y: 1100 },
                        data: { 
                          label: "CatBoost" , isSelected: false,
                          targetPosition: 'right',
                        },
                        style: nodeStyle,
                        type: 'customTripleHandle', 
                        originalWidth: nodeStyle.width,
                        originalHeight: nodeStyle.height,
                        group: null
                      },
                      {
                        id: 'AdaBoost',
                        position: { x: -3500, y: 1200 },
                        data: { 
                          label: "AdaBoost" , isSelected: false,
                          targetPosition: 'right',
                        },
                        style: nodeStyle,
                        type: 'customTripleHandle', 
                        originalWidth: nodeStyle.width,
                        originalHeight: nodeStyle.height,
                        group: null
                      },
                      {
                        id: 'Stacking',
                        position: { x: -3500, y: 1300 },
                        data: { 
                          label: "Stacking" , isSelected: false,
                          targetPosition: 'right',
                        },
                        style: nodeStyle,
                        type: 'customTripleHandle', 
                        originalWidth: nodeStyle.width,
                        originalHeight: nodeStyle.height,
                        group: null
                      },

  {
    id: '5SVM',
    position: { x: -3000, y: 700 },
    data: { 
      label: "SVM" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1:'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

                                  {
                                    id: 'LinearSVM',
                                    position: { x: -3800, y: 750 },
                                    data: { 
                                      label: "Linear SVM" , isSelected: false,
                                      targetPosition: 'right',
                                    },
                                    style: nodeStyle,
                                    type: 'customTripleHandle', 
                                    originalWidth: nodeStyle.width,
                                    originalHeight: nodeStyle.height,
                                    group: null
                                  },

                                  {
                                    id: 'KernelSVM',
                                    position: { x: -3800, y: 850 },
                                    data: { 
                                      label: "RBF, Polynomial, Sigmoid" , isSelected: false,
                                      targetPosition: 'right',
                                    },
                                    style: nodeStyle,
                                    type: 'customTripleHandle', 
                                    originalWidth: nodeStyle.width,
                                    originalHeight: nodeStyle.height,
                                    group: null
                                  },

  {
    id: '5retiNeurali',
    position: { x: -3000, y: 600 },
    data: { 
      label: "Reti neurali" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1:'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },
                                            {
                                              id: 'Perceptron',
                                              position: { x: -4100, y: 700 },
                                              data: { 
                                                label: "Perceptron" , isSelected: false,
                                                targetPosition: 'right',
                                              },
                                              style: nodeStyle,
                                              type: 'customTripleHandle', 
                                              originalWidth: nodeStyle.width,
                                              originalHeight: nodeStyle.height,
                                              group: null
                                            },
                                            {
                                              id: 'MLP',
                                              position: { x: -4100, y: 800 },
                                              data: { 
                                                label: "Multi-layer Perceptron" , isSelected: false,
                                                targetPosition: 'right',
                                              },
                                              style: nodeStyle,
                                              type: 'customTripleHandle', 
                                              originalWidth: nodeStyle.width,
                                              originalHeight: nodeStyle.height,
                                              group: null
                                            },
                                            {
                                              id: 'CNN',
                                              position: { x: -4100, y: 900 },
                                              data: { 
                                                label: "Convolutional Neural Networks" , isSelected: false,
                                                targetPosition: 'right',
                                              },
                                              style: nodeStyle,
                                              type: 'customTripleHandle', 
                                              originalWidth: nodeStyle.width,
                                              originalHeight: nodeStyle.height,
                                              group: null
                                            },
                                            {
                                              id: 'RNN',
                                              position: { x: -4100, y: 1000 },
                                              data: { 
                                                label: "Recurrent Neural Networks" , isSelected: false,
                                                targetPosition: 'right',
                                              },
                                              style: nodeStyle,
                                              type: 'customTripleHandle', 
                                              originalWidth: nodeStyle.width,
                                              originalHeight: nodeStyle.height,
                                              group: null
                                            },
                                            {
                                              id: 'LSTM',
                                              position: { x: -4100, y: 1100 },
                                              data: { 
                                                label: "Reti neurali" , isSelected: false,
                                                targetPosition: 'right',
                                              },
                                              style: nodeStyle,
                                              type: 'customTripleHandle', 
                                              originalWidth: nodeStyle.width,
                                              originalHeight: nodeStyle.height,
                                              group: null
                                            },
                                           
  {
    id: '5naiveBayes',
    position: { x: -3000, y: 500 },
    data: { 
      label: "Naive Bayes" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1:'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },
                              {
                                id: 'GaussianNaiveBayes',
                                position: { x: -4400, y: 650 },
                                data: { 
                                  label: "Gaussian Naive Bayes" , isSelected: false,
                                  targetPosition: 'right',
                                },
                                style: nodeStyle,
                                type: 'customTripleHandle', 
                                originalWidth: nodeStyle.width,
                                originalHeight: nodeStyle.height,
                                group: null
                              },
                              {
                                id: 'MultinomialNaiveBayes',
                                position: { x: -4400, y: 750 },
                                data: { 
                                  label: "Multinomial Naive Bayes" , isSelected: false,
                                  targetPosition: 'right',
                                },
                                style: nodeStyle,
                                type: 'customTripleHandle', 
                                originalWidth: nodeStyle.width,
                                originalHeight: nodeStyle.height,
                                group: null
                              },
                              {
                                id: 'BernoulliNaiveBayes',
                                position: { x: -4400, y: 850 },
                                data: { 
                                  label: "Bernoulli Naive Bayes" , isSelected: false,
                                  targetPosition: 'right',
                                },
                                style: nodeStyle,
                                type: 'customTripleHandle', 
                                originalWidth: nodeStyle.width,
                                originalHeight: nodeStyle.height,
                                group: null
                              },
                              {
                                id: 'ComplementNaiveBayes',
                                position: { x: -4400, y: 950 },
                                data: { 
                                  label: "Complement Naive Bayes" , isSelected: false,
                                  targetPosition: 'right',
                                },
                                style: nodeStyle,
                                type: 'customTripleHandle', 
                                originalWidth: nodeStyle.width,
                                originalHeight: nodeStyle.height,
                                group: null
                              },
  {
    id: '5knn',
    position: { x: -3000, y: 400 },
    data: { 
      label: "KNN" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5lda',
    position: { x: -3000, y: 300 },
    data: { 
      label: "LDA" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5qda',
    position: { x: -3000, y: 200 },
    data: { 
      label: "QDA" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5elm',
    position: { x: -3000, y: 100 },
    data: { 
      label: "ELM" , isSelected: false,
      targetPosition: 'right',
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '5g',
    position: { x: -2700, y: 0 },
    data: { 
      label: "Modelli di classificazione" , isSelected: false,
      targetPosition: 'right',
      sourcePosition1: 'left'
    },
    style: nodeStyle,
    type: 'customTripleHandle', 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '6',
    position: { x: -1600, y: 500 },
    data: { label: 'Features', isSelected: false },
    style: nodeStyle, 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '6a',
    position: { x: -1000, y: 500 },
    data: { label: 'Etichetta (La future da prevedere)', isSelected: false },
    style: nodeStyle, 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '6b',
    position: { x: -500, y: 500 },
    data: { label: 'Previsione', isSelected: false },
    style: nodeStyle, 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '7',
    position: { x: -1900, y: 600 },
    data: { label: 'Nome animale', isSelected: false },
    style: nodeStyle, 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '8',
    position: { x: -1700, y: 600 },
    data: { label: 'Tipo di animale', isSelected: false },
    style: nodeStyle, 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '9',
    position: { x: -1500, y: 600 },
    data: { label: 'Razza animale', isSelected: false },
    style: nodeStyle, 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '10',
    position: { x: -1300, y: 600 },
    data: { label: 'Peso animale', isSelected: false },
    style: nodeStyle, 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '10',
    position: { x: -1300, y: 600 },
    data: { label: 'Peso animale', isSelected: false },
    style: nodeStyle, 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '11',
    position: { x: -1000, y: 600 },
    data: { label: 'Sesso animale', isSelected: false },
    style: nodeStyle, 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '12',
    position: { x: -500, y: 600 },
    data: { label: "L'ipotesi fatta dal modello" , isSelected: false },
    style: nodeStyle, 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  {
    id: '13',
    position: { x: -500, y: 700 },
    data: { label: "Prevedere l'etichetta" , isSelected: false },
    style: nodeStyle, 
    originalWidth: nodeStyle.width,
    originalHeight: nodeStyle.height,
    group: null
  },

  
];

const edgeTypes = {
  customBezier: CustomBezierEdge,
};



const initialEdges = [
  { id: '1-2',  source: '1', target: '2', },
  { id: '2-3',  source: '2', target: '3', sourceHandle:'source3'},
  { id: '2-4',  source: '2', target: '4', sourceHandle:'source3'},
  { id: '2-4a', source: '2', target: '4a', sourceHandle:'source1', targetHandle:'targetPosition2'},
  { id: '2-4b', source: '2', target: '4b', sourceHandle:'source2'},
  { id: '2retiNeur-4', source: '2retiNeur', target: '4', sourceHandle:'source2'},
  { id: '2retiNeur-4b', source: '2retiNeur', target: '4b', sourceHandle:'source3'},
  { id: '3-4', source: '3', target: '4', sourceHandle:'source2'},
  { id: '3-4b', source: '3', target: '4b', sourceHandle:'source3'},

  { id: '2retiNeur', source: '2', target: '2retiNeur', sourceHandle: 'source3', targetHandle: 'targetPosition1' },
  { id: '2retiNeur', source: '2', target: '2retiNeur', sourceHandle: 'source2', targetHandle: 'targetPosition2' },

  { id: '2retiNeur', source: '2retiNeur', target: '3', sourceHandle: 'source3', targetHandle: 'targetPosition2' },
 
  { id: '4-4e', source: '4', target: '4e'},
  { id: '4-4ee', source: '4', target: '4ee'},
  { id: '4-4eee', source: '4', target: '4eee'},
  { id: '4-4pre', source: '4', target: '4pre', sourceHandle: 'source2'}, //Apprendimento supervisionato Preprocessing
  
  { id: '4eee-4e4', source: '4eee', target: '4e4'},
  { id: '4eee-4e5', source: '4eee', target: '4e5'},
  { id: '4eee-4e6', source: '4eee', target: '4e6'},
  { id: '4pre-5',  source: '4pre', target: '5', sourceHandle: 'source1'},//Preprocessing Dati
  
  { id: '5-5a',  source: '5', target: '5a', sourceHandle: 'source2'},
  { id: '5a-5a2',  source: '5a', target: '5a2', sourceHandle: 'source1'},
  { id: '5a-5a3',  source: '5a2', target: '5a3', sourceHandle: 'source3'},
  { id: '5a-5a4',  source: '5a2', target: '5a4', sourceHandle: 'source3'},
  { id: '5a2-5b', source: '5a2', target: '5b'},
  { id: '5a2-5c', source: '5a2', target: '5c'},
  { id: '5a-6', source: '5a', target: '6',sourceHandle: 'source1'},
  { id: '5a-6a', source: '5a', target: '6a',sourceHandle: 'source2'},
  { id: '5a-6b', source: '5a', target: '6b',sourceHandle: 'source3'},

  { id: '5a3-5a4', source: '5a3', target: '5a4',sourceHandle: 'source2'},
  
  { id: '5a3-5esplorazione', source: '5a3', target: '5esplorazione'},
  { id: '5a3-5selez', source: '5a3', target: '5selez'},
  { id: '5a3-5rimozDuplicati', source: '5a3', target: '5rimozDuplicati'},
  { id: '5a3-5valAnomali', source: '5a3', target: '5valAnomali'},
  { id: '5a3-5gestErrori', source: '5a3', target: '5gestErrori'},
  { id: '5a3-5binning', source: '5a3', target: '5binning'},
  { id: '5a3-5elabTesto', source: '5a3', target: '5elabTesto'},
  { id: '5a3-5serieTemp', source: '5a3', target: '5serieTemp'},
  { id: '5a3-5vettori', source: '5a3', target: '5vettori'},

  { id: '5a4-5normal', source: '5a4', target: '5normal'},
  { id: '5a4-5scaling', source: '5a4', target: '5scaling'},
  { id: '5a4-5encoding', source: '5a4', target: '5encoding'},
  { id: '5a4-5ridDimens', source: '5a4', target: '5ridDimens'},
  { id: '5a4-5imputazione', source: '5a4', target: '5imputazione'},

  { id: '5normal-5normalALG', source: '5normal', target: '5normalALG'},
  { id: '5scaling-5scalingALG', source: '5scaling', target: '5scalingALG'},
  { id: '5encoding-5encodingALG', source: '5encoding', target: '5encodingALG'},
  { id: '5ridDimens-5ridDimensALG', source: '5ridDimens', target: '5ridDimensALG'},
  { id: '5imputazione-5imputazioneALG', source: '5imputazione', target: '5imputazioneALG'},
  

  { id: '5b-5d', source: '5b', target: '5d'},
  { id: '5c-5e', source: '5c', target: '5e'},
  { id: '5d-5f', source: '5d', target: '5f'},
  { id: '5e-5g', source: '5e', target: '5g'},

  { id: '5g-5alberiDec', source: '5g', target: '5alberiDec'},
  { id: '5g-5EnsembleMethods', source: '5g', target: '5EnsembleMethods'},
  { id: '5g-5SVM', source: '5g', target: '5SVM'},
  { id: '5g-5retiNeurali', source: '5g', target: '5retiNeurali'},
  { id: '5g-5naiveBayes', source: '5g', target: '5naiveBayes'},
  { id: '5g-5knn', source: '5g', target: '5knn'},
  { id: '5g-5lda', source: '5g', target: '5lda'},
  { id: '5g-5qda', source: '5g', target: '5qda'},
  { id: '5g-5elm', source: '5g', target: '5elm'},

  { id: '5f-5RegressioneLineare', source: '5f', target: '5RegressioneLineare'},
  { id: '5f-5RegressioneLogistica', source: '5f', target: '5RegressioneLogistica'},
  { id: '5f-5KNN', source: '5f', target: '5KNN'},
  { id: '5f-5RegressionePolinomiale', source: '5f', target: '5RegressionePolinomiale'},
  { id: '5f-5SVR', source: '5f', target: '5SVR'},
  { id: '5f-5decTree', source: '5f', target: '5decTree'},
  { id: '5f-5rfr', source: '5f', target: '5rfr'},
  { id: '5f-5gbr', source: '5f', target: '5gbr'},
  { id: '5f-5nnr', source: '5f', target: '5nnr'},
  { id: '5f-5gpr', source: '5f', target: '5gpr'},

  { id: '5alberiDec-CART', source: '5alberiDec', target: 'CART'},
  { id: '5alberiDec-ID3', source: '5alberiDec', target: 'ID3'},
  { id: '5alberiDec-C4.5', source: '5alberiDec', target: 'C4.5'},
  { id: '5alberiDec-CHAID', source: '5alberiDec', target: 'CHAID'},

  { id: '5EnsembleMethods-Random Forest', source: '5EnsembleMethods', target: 'RandomForest'},
  { id: '5EnsembleMethods-LightGBM', source: '5EnsembleMethods', target: 'LightGBM'},
  { id: '5EnsembleMethods-CatBoost', source: '5EnsembleMethods', target: 'CatBoost'},
  { id: '5EnsembleMethods-AdaBoost', source: '5EnsembleMethods', target: 'AdaBoost'},
  { id: '5EnsembleMethods-Stacking', source: '5EnsembleMethods', target: 'Stacking'},

  { id: '5SVM-LinearSVM', source: '5SVM', target: 'LinearSVM'},
  { id: '5SVM-KernelSVM', source: '5SVM', target: 'KernelSVM'},
  
  { id: '5retiNeurali-Perceptron', source: '5retiNeurali', target: 'Perceptron'},
  { id: '5retiNeurali-MLP', source: '5retiNeurali', target: 'MLP'},
  { id: '5retiNeurali-CNN', source: '5retiNeurali', target: 'CNN'},
  { id: '5retiNeurali-RNN', source: '5retiNeurali', target: 'RNN'},
  { id: '5retiNeurali-LSTM', source: '5retiNeurali', target: 'LSTM'},

  { id: '5naiveBayes-GaussianNaiveBayes', source: '5naiveBayes', target: 'GaussianNaiveBayes'},
  { id: '5naiveBayes-MultinomialNaiveBayes', source: '5naiveBayes', target: 'MultinomialNaiveBayes'},
  { id: '5naiveBayes-BernoulliNaiveBayes', source: '5naiveBayes', target: 'BernoulliNaiveBayes'},
  { id: '5naiveBayes-ComplementNaiveBayes', source: '5naiveBayes', target: 'ComplementNaiveBayes'},

  { id: '5RegressioneLineare-OrdinaryLeastSquares', source: '5RegressioneLineare', target: 'OrdinaryLeastSquares'},
  { id: '5RegressioneLineare-L2', source: '5RegressioneLineare', target: 'L2'},
  { id: '5RegressioneLineare-L1', source: '5RegressioneLineare', target: 'L1'},
  { id: '5RegressioneLineare-ElasticNet', source: '5RegressioneLineare', target: 'ElasticNet'},

  { id: '5RegressioneLogistica-Binaria', source: '5RegressioneLogistica', target: 'Binaria'},
  { id: '5RegressioneLogistica-Multinomiale', source: '5RegressioneLogistica', target: 'Multinomiale'},
  { id: '5RegressioneLogistica-Ordinale', source: '5RegressioneLogistica', target: 'Ordinale'},

  { id: '6-7',  source: '6', target: '7'  },
  { id: '6-8',  source: '6', target: '8'  },
  { id: '6-9',  source: '6', target: '9'  },
  { id: '6-10', source: '6', target: '10' },
  { id:'6a-11', source: '6a', target: '11'},
  { id:'6b-12', source: '6b', target: '12'},
  
  { id:'12-13', source: '12', target: '13'},
];


function MachineLearningDiagram({ isLocked = true }) {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const getGroupIds = (clickedId) => {
    switch(clickedId) {
      case '1': // AI
        return ['1','2retiNeur', '2', '3'];
      case '2': // Machine Learning
        return ['1','2','2retiNeur','3', '4', '4a', '4b'];
      case '3': // Deep Learning
        return ['1','2retiNeur', '2', '3', '4', '4a', '4b'];
      case '2retiNeur':
        return ['1','2retiNeur', '2', '3', '4', '4a', '4b'];
      case '4':
        return ['4','4e','4ee','4eee','4e4','4e5','4e6']
      case '4eee':
        return ['4eee','4e4','4e5','4e6']
      case '4pre':
        return ['4pre','5','5a']
      case '5':
        return['4pre','5','5a','6','6a','6b']
      case '5a':
        return['5a','6','6a','6b']
      case '5a2':
        return['5a2','5a3','5a4','5esplorazione','5selez','5gestErrori','5valAnomali','5rimozDuplicati','5binning','5elabTesto','5serieTemp','5vettori','5ridDimens','5imputazione','5normal','5scaling','5encoding','5ridDimensALG','5imputazioneALG','5normalALG','5scalingALG','5encodingALG']
      case '5a3':
        return['5a3','5esplorazione','5selez','5rimozDuplicati','5valAnomali','5gestErrori','5binning','5elabTesto','5serieTemp','5vettori']
      case '5a4':
        return['5a4','5normal','5scaling','5encoding','5ridDimens','5imputazione','5ridDimensALG','5imputazioneALG','5normalALG','5scalingALG','5encodingALG']
      case '5normal':
        return['5normal','5normalALG']
      case '5scaling':
        return['5scaling','5scalingALG']
      case '5encoding':
            return['5encoding','5encodingALG']
      case '5imputazione':
            return['5imputazione','5imputazioneALG']
      case '5ridDimens':
            return['5ridDimens','5ridDimensALG']
      case '5alberiDec':
            return['5alberiDec','CART','ID3','C4.5','CHAID']
      case '5EnsembleMethods':
            return['5EnsembleMethods','RandomForest','LightGBM','CatBoost','AdaBoost','Stacking']
      case '5b':
      case '5d':
      case '5f':
        return['5b','5d','5f','5RegressioneLineare', '5RegressioneLogistica' ,'5KNN', '5RegressionePolinomiale', '5SVR', '5decTree', '5rfr', '5gbr',  '5nnr', '5gpr']
      case '5c':
      case '5e':
      case '5g':
        return['5c','5e','5g','5alberiDec','5EnsembleMethods', '5SVM',  '5retiNeurali' , '5naiveBayes', '5knn',  '5lda', '5qda', '5elm',]  
      case '5SVM':
        return['5SVM','LinearSVM','KernelSVM']
      case '6':
        return ['6','7','8','9','10']
      case '5retiNeurali':
        return ['5retiNeurali','Perceptron','MLP','CNN','RNN','LSTM']
      case '5naiveBayes':
        return ['5naiveBayes','GaussianNaiveBayes','MultinomialNaiveBayes','BernoulliNaiveBayes','ComplementNaiveBayes']
      case '5RegressioneLineare':
        return ['5RegressioneLineare','OrdinaryLeastSquares','L2','L1','ElasticNet']
      case '5RegressioneLogistica':
        return ['5RegressioneLogistica','Binaria','Multinomiale','Ordinale']
      case '6a':
        return ['6a','11']
      case '6b':
        return ['6b','12','13']
      case '12':
        return ['12','13']
      
      default:
        return [clickedId];
    }
  };


  const getGroupColor = (id) => {
    switch(id) {
      case '1': // AI
      case '2': // Machine Learning
      case '2retiNeur':
      case '3': // Deep Learning
        return '#e3f2fd'; // Blu chiaro
      case '4': // Apprendimento con supervisione
      case '4a': // Apprendimento senza supervisione
      case '4b': // Apprendimento rafforzato
      case '4e':
      case '4ee':
      case '4eee':
      case '4e4':
      case '4e5':
      case '4e6':
        return '#e3f2fd'; //  Blu chiaro
      case '5a2':
      case '5a3':
      case '5esplorazione':
      case '5selez':
      case '5rimozDuplicati':
      case '5valAnomali':
      case '5gestErrori':
      case '5binning':
      case '5elabTesto':
      case '5serieTemp':
      case '5vettori':
      case '5a4':
      case '5imputazione':
      case '5normal':
      case '5scaling':
      case '5encoding':
      case '5ridDimens':
      case '5ridDimensALG':
      case '5imputazioneALG':
      case '5normalALG':
      case '5scalingALG':
      case '5encodingALG':
        return '#e3f2fd';
      case '5':  //Dati
      case '4pre': 
      case '5a':
      case '6': 
      case '6a': 
      case '6b': 
      case '7':
      case '8':
      case '9':
      case '10':
      case '12':
      return '#ffcccc'; // Rosso chiaro
      case '5b':
      case '5c':
      case '5d':
      case '5e':
      case '5g':
      case '5f':
        return '#ccffdd';
      case '5alberiDec':
      case 'CART':
      case 'ID3':
      case 'C4.5':
      case 'CHAID':
        return '#ccffdd';
      case '5EnsembleMethods':
      case 'RandomForest':  
      case 'LightGBM':
      case 'CatBoost':
      case 'AdaBoost':
      case 'Stacking':
        return '#ccffdd';
      case '5SVM':
      case 'LinearSVM':
      case '5KernelSVMSVM':
        return'#ccffdd';
      case '5retiNeurali':
      case 'Perceptron':
      case 'MLP':
      case 'CNN':
      case 'RNN':
      case 'LSTM':
        return '#ccffdd';
      case '5naiveBayes':
      case 'GaussianNaiveBayes':
      case 'MultinomialNaiveBayes':
      case 'BernoulliNaiveBayes':
      case 'ComplementNaiveBayes':
        return '#ccffdd';
      case '5RegressioneLineare':
      case 'OrdinaryLeastSquares':
      case 'L2':
      case 'L1':
      case'ElasticNet':
       return '#ccffdd';
      case '5RegressioneLogistica':
      case 'Binaria':
      case 'Multinomiale':
      case 'Ordinale':
       return '#ccffdd';
       
      default:
        return '#ffffff'; // Bianco per default
    }
  };
  
  const getGroupBorderColor = (id) => {
    switch(id) {
      case '1': // AI
      case '2': // Machine Learning
      case '2retiNeur':
      case '3': // Deep Learning
        return '#2196f3'; // Blu più scuro
      case '4': // Apprendimentosupervisionato
      case '4a': // Apprendimento non supervisionato
      case '4b': // Apprendimento per rinforzo
      case '4e':
      case '4ee':
      case '4eee':
      case '4e4':
      case '4e5':
      case '4e6':
        return '#2196f3';
      case '5a2':
      case '5a3':
      case '5esplorazione':
      case '5selez':
      case '5gestErrori':
      case '5valAnomali':
      case '5rimozDuplicati':
      case '5binning':
      case '5elabTesto':
      case '5serieTemp':
      case '5vettori':
      case '5a4':
      case '5imputazione':
      case '5normal':
      case '5scaling':
      case '5encoding':
      case '5ridDimens':
      case '5ridDimensALG':
      case '5imputazioneALG':
      case '5normalALG':
      case '5scalingALG':
      case '5encodingALG':
        return '#2196f3';
      case '5': 
      case '4pre':
      case '5a':
      case '6a': 
      case '6b': 
      case '6c':
        return '#ff6666'; // 
      case '5alberiDec':
      case 'CART':
      case 'ID3':
      case 'C4.5':
      case 'CHAID':
         return '#00e64d';
      case '5EnsembleMethods':
      case 'RandomForest':  
      case 'LightGBM':
      case 'CatBoost':
      case 'AdaBoost':
      case 'Stacking':
        return '#00e64d';
      case '5SVM':
      case 'LinearSVM':
      case '5KernelSVMSVM':
        return'#00e64d'
      case '5retiNeurali':
      case 'Perceptron':
      case 'MLP':
      case 'CNN':
      case 'RNN':
      case 'LSTM':
        return '#00e64d';
      case '5naiveBayes':
      case 'GaussianNaiveBayes':
      case 'MultinomialNaiveBayes':
      case 'BernoulliNaiveBayes':
      case 'ComplementNaiveBayes':
        return '#00e64d';    
      case '5RegressioneLineare':
      case 'OrdinaryLeastSquares':
      case 'L2':
      case 'L1':
      case'ElasticNet':
        return '#00e64d';
      case '5RegressioneLogistica':
      case 'Binaria':
      case 'Multinomiale':
      case 'Ordinale':
        return '#00e64d';
      case '6':
      case '7':
      case '8':
      case '9':
      case '10':
      case '12':  
          return '#ff6666';
      case '5b':
      case '5c':
      case '5d':
      case '5e':
      case '5g':
      case '5f':
          return '#00e64d';

      default:
        return '#b3cccc'; // Grigio scuro per default
    }
    
  };

  const getEdgeStyle = (source, target, nodes) => {
    
    const sourceNode = nodes.find(n => n.id === source);
    const targetNode = nodes.find(n => n.id === target);
    
    if (!sourceNode || !targetNode) {
      return { stroke: '#b3cccc', strokeWidth: 5 };
    }

    const sourceColor = getGroupBorderColor(sourceNode.id);
    const targetColor = getGroupBorderColor(targetNode.id);

    if (sourceNode.data.isSelected && targetNode.data.isSelected && sourceColor === targetColor) {
      return { stroke: sourceColor, strokeWidth: 5 };
    }

    if (sourceNode.data.isSelected) {
      return { stroke: sourceColor, strokeWidth: 5 };
    }
    if (targetNode.data.isSelected) {
      return { stroke: targetColor, strokeWidth: 5 };
    }

    return { stroke: '#b3cccc', strokeWidth: 5 };
  };

  const onNodeClick = useCallback((event, clickedNode) => {
    const newGroupIds = getGroupIds(clickedNode.id);
    const newGroupColor = getGroupColor(clickedNode.id);
    const newGroupBorderColor = getGroupBorderColor(clickedNode.id);

    setNodes((nds) => {
      const updatedNodes = nds.map((n) => {
        const isInNewGroup = newGroupIds.includes(n.id);
        const wasSelected = n.data.isSelected;
        const isSelected = isInNewGroup;

        let style = { ...nodeStyle };

        if (isSelected) {
          style = {
            ...style,
            backgroundColor: newGroupColor,
            borderColor: newGroupBorderColor,
            borderWidth: '2px',
            borderStyle: 'solid',
            width: (nodeStyle.width * -1.1) + 'px',
            height: (nodeStyle.height * -1.1) + 'px',
            fontSize: '13.2px',
            zIndex: 1000,
          };
        } else if (wasSelected) {
          style = {
            ...style,
            backgroundColor: '#ffffff',
            borderColor: '#b3cccc',
            borderWidth: '1px',
            borderStyle: 'solid',
            width: nodeStyle.width + 'px',
            height: nodeStyle.height + 'px',
            fontSize: '12px',
            zIndex: 0,
          };
        } else {
          style = {
            ...n.style,
            width: nodeStyle.width + 'px',
            height: nodeStyle.height + 'px',
            fontSize: '12px',
            zIndex: 0,
          };
        }

        return {
          ...n,
          data: { ...n.data, isSelected, group: isInNewGroup ? clickedNode.id : null },
          style: style,
        };
      });

      setEdges((eds) =>
        eds.map((edge) => ({
          ...edge,
          style: getEdgeStyle(edge.source, edge.target, updatedNodes)
        }))
      );

      return updatedNodes;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        minZoom={0.2}
        defaultZoom={0.2}
        defaultEdgeOptions={{
          style: { stroke: '#b3cccc', strokeWidth: 5 },}}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        onNodeClick={onNodeClick}
        fitView
        panOnScroll={!isLocked}
        zoomOnScroll={!isLocked}
        panOnDrag={!isLocked}
        zoomOnDoubleClick={!isLocked}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
}
export default MachineLearningDiagram;